import { styled } from "goober";
import { mobile, tablet } from "clutch/src/Style/style.mjs";

export const Columns = styled("div")`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--sp-4);
  padding: var(--sp-6);

  .fake-button {
    &,
    &:hover {
      cursor: default;
      background: var(--shade6) !important;
      color: var(--shade0) !important;
    }
  }

  ${mobile} {
    grid-template-columns: 1fr;
  }
`;

export const Column = styled("div")`
  display: flex;
  flex-direction: column;
  gap: var(--sp-2);
`;

export const TypeBox = styled("div")`
  padding: var(--sp-6);
  background: var(--shade8);
  display: flex;
  line-height: 1.5;
  border-radius: var(--br);

  .type-icon {
    margin-right: var(--sp-3);
  }
`;

export const TypeRight = styled("div")`
  flex: 1;
`;

export const TypeBonuses = styled("div")`
  display: flex;
  flex-direction: column;
  margin: var(--sp-1) 0 var(--sp-6);
  font-size: var(--sp-3);
`;

export const TypeDescription = styled("p")`
  margin: 0 0 var(--sp-2);
  color: var(--shade1);
`;

export const TypeBonus = styled("div")`
  display: flex;
  margin-bottom: var(--sp-1);

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const TypeBonusNeeded = styled("span")`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--shade6);
  height: var(--sp-5);
  width: var(--sp-5);
  margin-right: var(--sp-2);
  color: var(--shade0);
  font-weight: 700;
  border-radius: var(--br-sm);
`;

export const TypeBonusEffect = styled("p")`
  margin: 0;
  flex: 1;
`;

export const Champions = styled("div")`
  display: grid;
  place-items: center;
  grid-template-columns: repeat(auto-fit, minmax(var(--sp-5), var(--sp-10)));
  grid-gap: var(--sp-2);
  ${tablet} {
    grid-template-columns: repeat(auto-fit, minmax(var(--sp-5), var(--sp-8)));
  }
`;
