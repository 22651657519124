import { styled } from "goober";

export const Grid = styled("div")`
  display: grid;
  grid-template-columns: 230px 1fr;
  gap: var(--sp-4);
`;

export const Left = styled("div")`
  display: flex;
  flex-direction: column;
  gap: var(--sp-4);
`;

export const Items = styled("div")`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: var(--sp-4);
  margin-top: var(--sp-2);

  .not-selected {
    opacity: 0.5 !important;
  }
`;

export const ComponentItems = styled("div")`
  display: flex;
  gap: var(--sp-3);
`;

export const Rows = styled("div")`
  padding: var(--sp-8);
  display: flex;
  flex-direction: column;
  gap: var(--sp-8);
`;

export const Row = styled("div")`
  display: flex;
  gap: var(--sp-9);
  align-items: center;
  justify-content: flex-start;
`;

export const BasicItems = styled("div")`
  .btn-group {
    margin-top: var(--sp-2);
  }
  .selected {
    background: var(--shade6) !important;
    border: 1px solid var(--shade3) !important;
  }

  .not-selected {
    opacity: 0.5 !important;
  }
`;
