export default (item, itemWith, itemStatic) => {
  if (
    [item, itemWith].some((i) => typeof i !== "string") ||
    typeof itemStatic !== "object"
  ) {
    return null;
  }
  for (const key in itemStatic) {
    const thisItem = itemStatic[key];
    if (typeof thisItem !== "object" || !Array.isArray(thisItem.buildsFrom)) {
      continue;
    }
    if (
      (thisItem.buildsFrom[0] === item &&
        thisItem.buildsFrom[1] === itemWith) ||
      (thisItem.buildsFrom[1] === item && thisItem.buildsFrom[0] === itemWith)
    ) {
      return thisItem;
    }
  }
  return null;
};
