import { useEffect, useState } from "react";

import clone from "@/util/clone.mjs";
import orderArrayBy from "@/util/order-array-by.mjs";

const ref = {};

export function useLegendsByParams({
  legends,
  legendsStats: _legendsStats,
  augments,
  localizedAugments,
  set,
  type = "ALL",
}) {
  const [state, setState] = useState([]);
  useEffect(() => {
    if (typeof ref.id === "number") {
      clearTimeout(ref.id);
      delete ref.id;
    }
    if (
      !legends ||
      !_legendsStats ||
      legends instanceof Error ||
      _legendsStats instanceof Error ||
      typeof _legendsStats === "undefined" ||
      typeof _legendsStats !== "object"
    ) {
      ref.id = setTimeout(() => setState([]), 2e3);
    } else {
      const results = [];
      const augmentData = parseAugmentData(augments);
      const legendsStats = clone(_legendsStats);

      for (const id in legendsStats) {
        const legendKey = Object.entries(legends).find(
          ([, d]) => parseInt(d.id) === parseInt(id),
        )?.[0];
        const legend = { ...legends[legendKey], objKey: legendKey };
        if (!legend) continue;
        const result = legendsStats[id];
        const legendResult = {
          ...result,
          ...legend,
          type: "legend",
        };
        const augments = [];
        for (const aug in result.augments_stats) {
          const staticData = augmentData?.[aug];
          if (!staticData) continue;
          const stages = augmentByStage(legends[legendKey]?.augments);
          const pickOrderData = {
            stage1: "2-1",
            stage2: "3-2",
            stage3: "4-2",
          };
          const augmentResult = {
            objKey: aug,
            name: localizedAugments?.[aug]?.name ?? staticData?.name,
            description:
              localizedAugments?.[aug]?.description ?? staticData?.description,
            tier: result.augments_stats?.[aug]?.tier,
            pick_rate: result.augments_stats?.[aug]?.board_pick_rate,
            type: "augment",
            stage: pickOrderData[stages?.[aug]],
          };
          augments.push(augmentResult);
        }
        orderArrayBy(augments, ["tier", "pick_rate"], "asc");
        results.push({ ...legendResult, augments: augments });
      }

      setState(results);
    }
  }, [legends, _legendsStats, augments, set, type]);
  return state;
}

const parseAugmentData = (augments) => {
  return {
    ...augments?.["tier1"],
    ...augments?.["tier2"],
    ...augments?.["tier3"],
  };
};

export const augmentByStage = (augments) => {
  const result = {};
  if (!augments) return result;
  for (const tier in augments) {
    for (const stage in augments[tier]) {
      const augment = augments[tier][stage];
      if (augment) result[augment] = stage;
    }
  }
  return result;
};
