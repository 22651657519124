import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import OverviewChampions, {
  meta as OverviewChampionsMeta,
} from "@/game-tft/OverviewChampions.jsx";
import OverviewItems, {
  meta as OverviewItemsMeta,
} from "@/game-tft/OverviewItems.jsx";
import OverviewTraits, {
  meta as OverviewTraitsMeta,
} from "@/game-tft/OverviewTraits.jsx";
import StatsAugments, {
  meta as StatsAugmentsMeta,
} from "@/game-tft/StatsAugments.jsx";
import StatsChampions from "@/game-tft/StatsChampions.jsx";
import StatsItems, { meta as StatsItemsMeta } from "@/game-tft/StatsItems.jsx";
import { meta as StatsLegendsMeta } from "@/game-tft/StatsLegends.jsx";
import StatsOverviewLayout from "@/game-tft/StatsOverviewLayout.jsx";
import StatsTraits, {
  meta as StatsTraitsMeta,
} from "@/game-tft/StatsTraits.jsx";
import StatisticsIcon from "@/inline-assets/statistics.svg";
import TFTAugmentIcon from "@/inline-assets/tft-augments.svg";
import ItemsIcon from "@/inline-assets/tft-items.svg";
import ItemsStatsIcon from "@/inline-assets/tft-items-stats.svg";
import { useRoute } from "@/util/router-hooks.mjs";

function StatsOverview() {
  const { t } = useTranslation();
  const { parameters } = useRoute();
  const key = parameters.join("/");
  const { Component, Icon, title } = useMemo(() => {
    switch (key) {
      case "champions/overview":
        return {
          Component: OverviewChampions,
          Icon: StatisticsIcon,
          title: t("tft:overview.champions", "Champions Overview"),
        };
      case "items/overview":
        return {
          Component: OverviewItems,
          Icon: ItemsIcon,
          title: t("common:navigation.items", "Items"),
        };
      case "traits/overview":
        return {
          Component: OverviewTraits,
          Icon: StatisticsIcon,
          title: t("tft:overview.traits", "Traits Overview"),
        };
      case "champions/stats":
        return {
          Component: StatsChampions,
          Icon: StatisticsIcon,
          title: t("lol:search.statistics", "Statistics"),
        };
      case "traits/stats":
        return {
          Component: StatsTraits,
          Icon: StatisticsIcon,
          title: t("lol:search.statistics", "Statistics"),
        };
      case "items/stats":
        return {
          Component: StatsItems,
          Icon: ItemsStatsIcon,
          title: t("common:navigation.itemStats", "Item Stats"),
        };
      case "augments/overview":
      case "augments/stats":
        return {
          Component: StatsAugments,
          Icon: TFTAugmentIcon,
          title: t("common:navigation.augmentStats", "Augment Stats"),
        };
      default:
        return Object.create(null);
    }
  }, [key, t]);

  if (typeof Component === "undefined") return null;

  return (
    <StatsOverviewLayout
      title={title}
      Icon={Icon}
      filters={<Component.FilterBar />}
      nowrap={key === "itemsoverview"}
    >
      <Component.View />
    </StatsOverviewLayout>
  );
}

export default StatsOverview;

export function meta(parameters) {
  const key = parameters.join("/");

  switch (key) {
    case "items/overview":
      return OverviewItemsMeta();
    case "traits/overview":
      return OverviewTraitsMeta();
    case "traits/stats":
      return StatsTraitsMeta();
    case "items/stats":
      return StatsItemsMeta();
    case "augments/stats":
      return StatsAugmentsMeta();
    case "legends/stats":
      return StatsLegendsMeta();
    default:
      return OverviewChampionsMeta();
  }
}
